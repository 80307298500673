import React from 'react';
import { pingWebsocket } from '../actions/actionWebsocket';
import { modules } from '../constants/Utils';
import { connect } from 'react-redux';

class WebsocketHeartbeat extends React.Component {
    componentDidMount() {
        this.pingAllWebsockets();
        setInterval(this.pingAllWebsockets, 540 * 1000);
    }

    pingAllWebsockets = () => {
        const { pingWebsocket } = this.props;
        pingWebsocket(modules);
    };

    render() {
        return (<></>);
    }
}

export default connect(null, {
    pingWebsocket
})(WebsocketHeartbeat);

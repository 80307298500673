import {
    HDI_TICKET_STATUS_CLOSED,
    HDI_TICKET_STATUS_NEW,
    HDI_TICKET_STATUS_ON_HOLD,
    HDI_TICKET_STATUS_OPENED,
    HDI_TICKET_STATUS_SUSPENDED,
    HDI_TICKET_STATUS_NEWOPENED,
    HDI_TICKET_STATUS_REOPENED
} from './form/statusConstants';
import {
    HDI_TICKET_PRIORITY_P1,
    HDI_TICKET_PRIORITY_P2,
    HDI_TICKET_PRIORITY_P3,
    HDI_TICKET_PRIORITY_P4
} from './form/priorityConstants';
import { HDI_SG_LEVEL_L0, HDI_SG_LEVEL_L1, HDI_SG_LEVEL_L2 } from './form/levelConstants';
import { NISSAN, RENAULT } from './form/brandConstants';
import {
    HDI_TICKET_INCIDENT_TYPE_INCIDENT,
    HDI_TICKET_INCIDENT_TYPE_SERVICE_REQUEST
} from './form/incidentTypeConstants';
import { R_NET, SUBSIDIARY } from './form/formSubstitutionConstants';

export const ALL_COUNTRIES_VALUE = 'ALL';
export const SORT_ASC = 'asc';
export const SORT_DESC = 'desc';
export const SORT_NONE = '';
export const REQUEST_PARAM_DELIM = ':';

export const providers = {
    IDP: 'IDP',
    OKTA: 'OKTA'
}

export const domains = 'HDI';
export const modules = domains;

export const countries = {
    CZ: 'CZ',
    SK: 'SK',
    HU: 'HU',
    SE: 'SE',
    NO: 'NO',
    FI: 'FI',
    DK: 'DK',
    NL: 'NL',
    PL: 'PL',
    BE: 'BE',
    UK: 'UK',
    IE: 'IE',
    MT: 'MT',
    CY: 'CY',
    LT: 'LT',
    LV: 'LV',
    EE: 'EE',
    FR: 'FR'
};

export function getAllCountries() {
    return Object.keys(countries).map(country => ({group: country, value: true}))
}

export const adminRoles = {
    ADMIN_ROLE: 'ADMIN_ROLE'
};

export const dealerRoles = {
    SPECIAL_DEALER: 'SPECIAL_DEALER'
};

export const languages = ['cs', 'en', 'hu', 'sk', 'pl'];

export const locations = [
    'cs-CZ',
    'en-GB',
    'pl-PL',
];

export const getAllFormStates = [
    HDI_TICKET_STATUS_CLOSED,
    HDI_TICKET_STATUS_NEW,
    HDI_TICKET_STATUS_ON_HOLD,
    HDI_TICKET_STATUS_OPENED,
    HDI_TICKET_STATUS_REOPENED,
    HDI_TICKET_STATUS_SUSPENDED,
    HDI_TICKET_STATUS_NEWOPENED
];

export const getPriority = [
    HDI_TICKET_PRIORITY_P1,
    HDI_TICKET_PRIORITY_P2,
    HDI_TICKET_PRIORITY_P3,
    HDI_TICKET_PRIORITY_P4
];

export const getIncidentType = [
    HDI_TICKET_INCIDENT_TYPE_INCIDENT,
    HDI_TICKET_INCIDENT_TYPE_SERVICE_REQUEST,
];

export const getBrands = [
    RENAULT,
    NISSAN,
];

export const getLevel = [
    HDI_SG_LEVEL_L0,
    HDI_SG_LEVEL_L1,
    HDI_SG_LEVEL_L2
];

export const getUserType = [
    SUBSIDIARY,
    R_NET
];


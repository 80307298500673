import labels from '../labels/index';

// Define user's language. Different browsers have the user locale defined
// on different fields on the `navigator` object, so we make sure to account
// for these different by checking all of them

// TODO: moment settings
// moment.locale(window.navigator.userLanguage || window.navigator.language);
// moment.locale('cs');

const locale = (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage || navigator.browserLanguage;

// Split locales with a region code
const languageWithoutRegionCode = locale.toLowerCase().split(/[_-]+/)[0];

// Try full locale, fallback to locale without region code, fallback to en
const messages = labels[languageWithoutRegionCode] || labels[locale] || labels.en;

/**
 * Initial values of important properties
 */
const initialState = {
    intl: {
        language: languageWithoutRegionCode,
        locale,
        messages,
    },
};

export default initialState;

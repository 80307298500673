import axios from 'axios';
import axiosConfig from '../constants/axiosConfig';
import {authenticateAndRedirect, getAuthenticatedUser, REFERRER_LOCAL_STORAGE} from '../utils/auth';

export let axiosInstance = {};

export const initAxios = user => {
    axiosInstance = axios.create(Object.assign(
        {},
        axiosConfig,
        {headers: {common: {Authorization: user.access_token }}},)
    );
    axiosInstance.interceptors.response.use(
        undefined,
        error => {
            getAuthenticatedUser().then(authenticatedUser => {
                if (authenticatedUser && authenticatedUser.expired) {
                    return authenticateAndRedirect()
                        .then(() => {
                            localStorage.setItem(REFERRER_LOCAL_STORAGE, window.location.pathname + window.location.search);
                            return (delay => new Promise(resolve => setTimeout(resolve, delay)))(1000);
                        })
                        .then(() => axiosInstance({
                            ...error.config,
                            baseURL: '',
                        }))
                        .catch(() => Promise.reject(error));
                }
            });
            return Promise.reject(error);
        }
    );
};

export const axiosFileProgressInstance = axios.create(Object.assign(
    {},
    {responseType: 'blob'})
);



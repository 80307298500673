import { errorAlert, infoAlert, warnAlert } from '../actions/alertsActions';
import { CONNECT_HDI_WEBSOCKET_PENDING, CONNECT_HDI_WEBSOCKET_SUCCESS } from '../actions/actionWebsocket';
import errorMessages from '../intl/common/errorMessages';
import warnMessages from '../intl/common/warnMessages';
import alertMessages from '../intl/common/alertMessages';
import { authenticateAndRedirect, getAuthenticatedUser } from '../utils/auth';
import buttonMessages from '../intl/common/buttonMessages';
import { modules } from '../constants/Utils';
import get from 'get-value';
import { HDI_ATTACHMENTS_DOWNLOAD } from '../constants/websocketCommands';
import { hdiTicketWebsocketCommands } from '../constants/hdiTicketWebsocketCommands';
import axios from 'axios';
import FileSaver from 'file-saver';


const infoAlertUniqueId = '3466b8ae-0855-43a1-bd7c-257d2a79a3f8';
const webSockets = {};

export const createHdiSocketMiddleware = () => {
    return ({ dispatch, getState }) => next => async action => {
        if (action.type === CONNECT_HDI_WEBSOCKET_PENDING) {
            webSockets[modules] = new WebSocket(`${process.env.REACT_APP_BE_HDI_WS_URL}?Auth=${action.payload.token}`);
            webSockets[modules].onopen = () => dispatch({ type: CONNECT_HDI_WEBSOCKET_SUCCESS });
            webSockets[modules].onmessage = async message => {
                let messageObj = JSON.parse(message.data);
                if (!messageObj.command) {
                    dispatch(errorAlert(alertMessages.HDI_BE_UNREACHABLE, messageObj));
                } else {
                    if (messageObj.error) {
                        dispatch(errorAlert(errorMessages[get(messageObj, 'error.errCode', undefined)] ? errorMessages[messageObj.error] : alertMessages.HDI_WS_STRUCT_ERROR,
                            messageObj, messageObj.error));
                    }
                    if (messageObj.warning) {
                        const { ...messageWarnObj } = messageObj;
                        dispatch(warnAlert(warnMessages[get(messageObj, 'warning.warnCode', undefined)] ? warnMessages[messageObj.warning] : messageObj.warning, messageWarnObj, messageObj.warning));
                    }
                    if (messageObj.command === HDI_ATTACHMENTS_DOWNLOAD) {
                        const { url, name } = messageObj.attachment;
                        await axios.get(url, { responseType: 'blob' }).then(
                            result => FileSaver.saveAs(result.data, name),
                            error => dispatch(errorAlert(alertMessages.FILE_DOWNLOAD_ERROR, error))
                        );
                    }
                    if (messageObj.command === hdiTicketWebsocketCommands.HDI_TICKET_EXPORT_DOWNLOAD) {
                        const {url, type} = messageObj.exportRecord;
                        await axios.get(url, {responseType: 'blob'}).then(
                            result => FileSaver.saveAs(result.data, `export.${type}`),
                            error => dispatch(errorAlert(alertMessages.FILE_DOWNLOAD_ERROR, error))
                        );
                    }
                    dispatch({
                        type: messageObj.command,
                        payload: { ...messageObj }
                    });
                }
            };
            // webSockets[modules.HDI].onerror = error =>
            //     dispatch(errorAlert(alertMessages.HDI_WS_ERROR, error));
            webSockets[modules].onclose = async () =>
                webSockets[modules] = await reinitializeClosedSocket(webSockets[modules], process.env.REACT_APP_BE_HDI_WS_URL, action, dispatch);
        }
        if (action.hdiWebsocket) {
            attemptWsCall(dispatch, getState, action, modules, alertMessages.HDI_BE_UNREACHABLE);
        }
        return next(action);
    }
};

const reinitializeClosedSocket = async (socket, socketUrl, action, dispatch) => {
    let result = socket;

    const authenticatedUser = await getAuthenticatedUser();
    if (authenticatedUser && authenticatedUser.expired) {
        dispatch(infoAlert(
            alertMessages.TOKEN_EXPIRED,
            [
                {
                    buttonLabel: buttonMessages.YES,
                    handleClick: async function () {
                        if(process.env.REACT_APP_PROVIDER_SWITCH) {
                            localStorage.removeItem("provider")
                            localStorage.removeItem("providerExpireAt")
                            window.location.reload()
                        } else {
                            await authenticateAndRedirect();
                        }
                    }
                }
            ],
            {},
            infoAlertUniqueId
        ));
    } else {
        const { onopen, onmessage, onerror, onclose } = result;
        result = new WebSocket(`${socketUrl}?Auth=${authenticatedUser.access_token}`);
        Object.assign(result, { onopen, onmessage, onerror, onclose });
    }

    return result;
};

/**
 * The purpose of this function is to keep checking whether the WebSocket is properly connected. It checks only for
 * a certain amount of attempts (REACT_APP_WS_CONN_ATTEMPTS) and waits for certain amount of milliseconds before each
 * attempt (REACT_APP_WS_CONN_INTERVAL). If the connection is established in time, the request is sent to the WebSocket.
 * If not, error alert is dispatched.
 *
 * Why do we do this? Because on some environments it takes some time for each WebSocket to be connected (cca. 2 seconds)
 * and if we don't wait for it somehow, the request is just lost (and frontend keeps spinning the wheel forever).
 * But we cannot handle this only during initialization of the application because user can also access a particular
 * URL address (like for example ticket detail). So we need some general solution on one place for all WebSocket
 * requests.
 *
 * If you have a more elegant solution to this problem, feel free to share your idea.
 *
 * @param dispatch
 * @param getState
 * @param action
 * @param module
 * @param alertMessage
 * @param counter
 * @returns {Promise<void>}
 */
const attemptWsCall = async (dispatch, getState, action, module, alertMessage, counter = 0) => {
    const isConnected = get(getState(), `websocket.isConnected.${module}`, false);

    if (webSockets[module] && isConnected) {
        webSockets[module].send(JSON.stringify({
            command: action.command,
            message: { correlationId: new Date(), ...action.payload }
        }));
    } else if (counter < process.env.REACT_APP_WS_CONN_ATTEMPTS) {
        await (interval => new Promise(resolve => setTimeout(resolve, interval)))(process.env.REACT_APP_WS_CONN_INTERVAL);
        await attemptWsCall(dispatch, getState, action, module, alertMessage, counter + 1);
    } else {
        dispatch(errorAlert(alertMessage));
    }
};

import React from 'react';
import styled from 'styled-components';
import {withRouter } from 'react-router-dom';
import {PRIMARY_DARK_GREY} from '../theme/colors';
import renaultCar from '../images/car.png';
import {HOME_PATH} from '../routes/paths';
import Header from "./Header";
import { PrimaryButton } from './common/Button';
import { providers } from '../constants/Utils';

const TopNavigation = styled.nav`
    font-family: sans-serif;
    font-weight: bold;
    padding: 1px 10px;
    background-color: ${PRIMARY_DARK_GREY};
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const Main = styled.div`
    display: flex;
    flex: 1;
    overflow: auto;
`;

const Img = styled.img`
    padding-top: 16px;
    height: 35%;
    width: 35%;
`;

const Login = ({history}) => {
    return <Wrapper>
        <Header/>
        <TopNavigation className="navbar navbar-expand-md nav-pills" />
        <Main>
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <h1 className="text-center">Login</h1>
                        <div className="text-center">
                            <PrimaryButton className="btn mr-2" onClick={() => {
                                localStorage.setItem("provider", providers.IDP)
                                localStorage.setItem("providerExpireAt", new Date().valueOf() + 1800000) // 30 min expiration
                                history.push(HOME_PATH)
                            }}>Keycloak</PrimaryButton>
                            <PrimaryButton className="btn" onClick={() => {
                                localStorage.setItem("provider", providers.OKTA)
                                localStorage.setItem("providerExpireAt", new Date().valueOf() + 1800000) // 30 min expiration
                                history.push(HOME_PATH)
                            }}>Okta</PrimaryButton>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="text-center">
                            <Img src={renaultCar}/>
                        </div>
                    </div>
                </div>
            </div>
        </Main>
    </Wrapper>
}

export default withRouter(Login);

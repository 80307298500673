import {put, takeEvery} from 'redux-saga/effects';
import { ADMIN_USER_SETTINGS_SEND, closeSupportModal } from "../actions/actionSupport";

export function* handleSettingsUpdated() {
    yield put(closeSupportModal());
};

function* supportModalSaga() {
    yield takeEvery(ADMIN_USER_SETTINGS_SEND, handleSettingsUpdated);
}

export default supportModalSaga;

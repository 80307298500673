export const HOME_PATH = '/';
export const LOGOUT_PATH = '/logout';
export const LOGIN_PATH = '/login';

export const PROFILE_PATH = '/profile';


export const HDI_PATH = '/hdi';
export const HDI_TICKET_NEW_PATH = `${HDI_PATH}/new`;
export const HDI_TICKET_LIST_PATH = `${HDI_PATH}/list`;
export const HDI_TICKET_SEARCH_PATH = `${HDI_PATH}/search`;
export const HDI_TICKET_EXPORT_PATH = `${HDI_PATH}/export`;

export const DETAIL_PATH = 'detail';

export const ADMINISTRATION_PATH = '/administration';
export const ADMINISTRATION_RIGHTS_PATH = `${ADMINISTRATION_PATH}/right`;
export const ADMINISTRATION_RIGHTS_ADMIN_PATH = `${ADMINISTRATION_RIGHTS_PATH}/admin`;
export const ADMINISTRATION_RIGHTS_DEALER_PATH = `${ADMINISTRATION_RIGHTS_PATH}/dealer`;

export const ADMINISTRATION_SOLUTION_GROUP_PATH = `${ADMINISTRATION_PATH}/solutionGroup`;
export const ADMINISTRATION_SOLUTION_GROUP_LIST_PATH = `${ADMINISTRATION_SOLUTION_GROUP_PATH}/list`;
export const ADMINISTRATION_SOLUTION_GROUP_NEW_PATH = `${ADMINISTRATION_SOLUTION_GROUP_PATH}/new`;
export const ADMINISTRATION_SOLUTION_GROUP_DEFAULT_PATH = `${ADMINISTRATION_SOLUTION_GROUP_PATH}/default`;

export const ADMINISTRATION_TEMPLATE_PATH = `${ADMINISTRATION_PATH}/template`;
export const ADMINISTRATION_TEMPLATE_LIST_PATH = `${ADMINISTRATION_TEMPLATE_PATH}/list`;
export const ADMINISTRATION_TEMPLATE_NEW_PATH = `${ADMINISTRATION_TEMPLATE_PATH}/new`;

export const ADMINISTRATION_EDITOR_PATH = `${ADMINISTRATION_PATH}/editor`;
export const ADMINISTRATION_EDITOR_LIST_PATH = `${ADMINISTRATION_EDITOR_PATH}/list`;
export const ADMINISTRATION_EDITOR_NEW_PATH = `${ADMINISTRATION_EDITOR_PATH}/new`;

export const ADMINISTRATION_FREQUENT_QUESTION_PATH = `${ADMINISTRATION_PATH}/frequentQuestion`;

export const ADMINISTRATION_WELCOME_PATH = `${ADMINISTRATION_PATH}/welcome`;


import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TABLE_NO_DATA: {
        id: 'table.no.data',
        defaultMessage: 'No data found'
    },
    TABLE_PAGE: {
        id: 'table.page',
        defaultMessage: 'Page'
    },
    TABLE_OF: {
        id: 'table.of',
        defaultMessage: 'of'
    },
    TABLE_ROWS: {
        id: 'table.rows',
        defaultMessage: 'rows'
    }
});

export default messages;
